<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Calculate the molality of each of the solutions described below:</p>

      <p class="mb-3">
        a) A solution is prepared by dissolving
        <number-value :value="massSolute1" unit="\text{g}" />
        of ammonium chloride,
        <chemical-latex content="NH4Cl," />
        in
        <number-value :value="massSolvent1" unit="\text{g}" />
        of water.
      </p>

      <calculation-input
        v-model="inputs.molality1"
        class="mb-5"
        prepend-text="$\text{Molality:}$"
        append-text="$\text{m}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) The solution contains
        <number-value :value="massSolute2" unit="\text{g}" />
        of sodium chloride,
        <chemical-latex content="NaCl," />
        in
        <number-value :value="volumeSolvent" unit="\text{mL}" />
        of water. Note: you can assume that the density of water is 1.000 g/mL.
      </p>

      <calculation-input
        v-model="inputs.molality2"
        prepend-text="$\text{Molality:}$"
        append-text="$\text{m}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question411',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molality1: null,
        molality2: null,
      },
    };
  },
  computed: {
    massSolute1() {
      return this.taskState.getValueBySymbol('massSolute1').content;
    },
    massSolvent1() {
      return this.taskState.getValueBySymbol('massSolvent1').content;
    },
    massSolute2() {
      return this.taskState.getValueBySymbol('massSolute2').content;
    },
    volumeSolvent() {
      return this.taskState.getValueBySymbol('volumeSolvent').content;
    },
  },
};
</script>
